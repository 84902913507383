import styled from 'styled-components'

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 64px;
    height: 100%;
    gap: 8px;
    overflow: hidden;

    box-sizing: border-box;
    padding-bottom: 8px;
`

const HorizontalLayout = ({LogoComponent, ButtonsComponent, UserComponent}) => {


        return (
          <Root>
            <LogoComponent />

            <div style={{gap: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden'}}>
              <ButtonsComponent orientation={'horizontal'} expanded={false}/>
            </div>
            <div style={{flex: 1}} />

            <UserComponent expanded={false}/>
          </Root>


  )
}

export default HorizontalLayout
