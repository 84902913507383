import {Icon} from 'frontcore'
import React from 'react'
import styled, {css, useTheme} from 'styled-components'
import {createTransitions} from '../../helpers'

const fulWidthCss = css`
  width: 100%;
`

const Root = styled.div`
    cursor: default;
    overflow: hidden;
    ${({$fullWidth}) => $fullWidth && fulWidthCss}
    padding-left: ${({$padding}) => $padding + 'px'};
    padding-right: 8px;
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    gap: 4px;
    transition: ${createTransitions(['background-color'])};
    background-color: ${({theme, $active}) =>
            $active
                    ? theme.palette['surface-secondary']
                    : 'transparent'};
    color: ${({theme}) => theme.palette.text.tertiary};

    &:hover {
        &:hover {
            background-color: ${({theme}) =>
                    theme.palette['surface-tertiary']};
        }
    }
`

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TypeContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 8px;
  //font-weight: 900;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.text.tertiary};
`
const RButton = ({
  name,
  type,
  icon,
  IconComponent,
  iconComponentProps,
  onClick,
  children,
  active,
  StartComponent,
  startComponentProps,
  padding = 4,
  fullWidth = false
}) => {
  const {palette} = useTheme()

  return (
    <Root
      onClick={onClick}
      $fullWidth={fullWidth}
      $active={active}
      $padding={padding}
    >
      <div
        style={{
          display: 'flex',
          gap: 4,
          alignItems: 'center',
          overflow: 'hidden'
        }}
      >
        {StartComponent && (
          <StartComponent {...startComponentProps} />
        )}
        {IconComponent && (
          <IconComponent {...iconComponentProps} />
        )}
        {icon && (
          <IconContainer>
            <Icon
              icon={icon}
              size={18}
              color={palette.text.tertiary}
            />
          </IconContainer>
        )}

        <div
          style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}
        >
          <NameContainer>{name}</NameContainer>
          {type && <TypeContainer>{type}</TypeContainer>}
        </div>
      </div>
      <div style={{display: 'flex', gap: 4}}>
        {children}
      </div>
    </Root>
  )
}

export default RButton
