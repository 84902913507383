import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect, useMemo, useState} from 'react'
import useFuture from '../../../../hooks/useFuture'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {find, join, map, pathOr, length} from 'ramda'
import {EMPTY_ARRAY, EMPTY_OBJECT} from '../../../../constants'
import {fork} from 'fluture'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import DetailsHeader from '../../../../components/DetailsHeader'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import ReactJson from 'react-json-view'
import getRole from '../../../../api/security/getRole'
import LabelIcon from '../../../../components/Icons/LabelIcon'
import {Tabs} from 'frontcore'
import {CountMiniature} from '../../../../components/miniatures'
import * as PropTypes from 'prop-types'
import RolesSingleButtons from '../../Components/RolesButtons/RolesSingleButtons'
import {useNotificationContext} from "../../../../hooks/useNotificationsContext";
import useFetch from "../../../../hooks/useFetch";
import getPermissionsFetch from "../../../../api/security/getPermissionsFetch";
import {singleCallFetchOptions} from "../../../../api/helpers";
import PermissionsSelect from "../../../../components/PermissionsSelect";
import StatusIndicator from "../../../../components/StatusIndicator";
import Chip from "../../../../components/Chip";
import Json from "../../../../components/Json";

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

function RoleSingleButtons(props) {
  return null
}

RoleSingleButtons.propTypes = {
  redirect: PropTypes.bool,
  texts: PropTypes.bool,
  data: PropTypes.shape({}),
  getFuture: PropTypes.any,
  pending: PropTypes.bool,
}
const SecurityRoleDetailsView = () => {
  const getRoleFuture = useFuture(getRole)

  const {roleId, clusterName} = useParams()

  const dispatch = useDispatch()

  const [tab, setTab] = useState('permissions')

  const {data: permissionsOptions = EMPTY_ARRAY} = useFetch(
      getPermissionsFetch,
      singleCallFetchOptions
  )

  const role = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'role',
      'response',
    ])
  )

  const {
    name,
    users,
    groups,
    enabled,
    creationTime,
    description,
      state,

    permissions = EMPTY_ARRAY,
  } = role

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    if (roleId) {
      fork(({message}) => {
        createNotification({
          title: 'Failed to fetch Role',
          message,
          variant: 'error',
          autoHide: true,
        })
      })(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getRoleFuture({
          params: [{key: 'id', value: roleId}],
        })
      )
    }
  }, [roleId])

  const topContent = (
    <DetailsHeader
      pending={false}
      icon={
        <Chip size={'large'} type={"ROLE"}>
          {name}
        </Chip>
      }
      data={[
        {
          key: 'description',
          value: description,
        },
        {
          key: 'state',
          value: state,
          Component: ({value}) => <StatusIndicator
              value={value}
              variant={value === 'ENABLED' ? 'success' : 'default'}
          />
        },
        {
          key: 'Creation Time',
          value: creationTime,
          Component: DateCellRow,
        },

        {
          key: 'users',
          value: users,
        },
        {
          key: 'groups',
          value: groups,
        },
        {
          key: 'permissions',
          value: permissions,
          Component: ({value}) => length(value),
        },

      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
          value={tab}
          onChange={setTab}
          options={[
            {
              id: 'permissions',
              Component: TabComponent,
              componentProps: {
                label: 'Permissions',
              },
            },
            {
              id: 'raw',
              Component: TabComponent,
              componentProps: {
                label: 'Raw',
              },
            },
          ]}
      />
      <RolesSingleButtons
        texts={true}
        getFuture={getRoleFuture({
          params: [{key: 'id', value: roleId}],
        })}
        pending={false}
        data={role}
        redirect={false}
      />
    </div>
  )


  const bottomContent = (
      <>
        {tab === 'raw' && <Json src={role} />}
        {tab === 'permissions' && (
            <PermissionsSelect
                value={permissions}
                options={permissionsOptions}
            />
        )}
      </>
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityRoleDetailsView