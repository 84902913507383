import {Button, CheckBox, Input, Select} from 'frontcore'
import {map, prop} from 'ramda'
import {EMPTY_ARRAY} from '../../../../constants'
import PermissionsSelect from '../../../../components/PermissionsSelect'
import requiredValidator from "../../../../validators/requiredValidator";

const schema = (data, options) => {
  const {pending, navigate, permissions = EMPTY_ARRAY} = options


  const a = map(prop('title'), permissions)
  return {
    fields: [
      {
        id: 'name',
        section: 'General',
        name: 'Name',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        id: 'description',
        section: 'General',
        name: 'Description',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [],
      },
      {
        id: 'permissions',
        section: 'Permissions',
        name: 'Permissions',
        description:
          '',
        Component: PermissionsSelect,
        componentProps: {
          options: permissions,
          skeleton: pending,
        },
        validators: [requiredValidator],
      },
      {
        id: 'state',
        section: 'General',
        name: 'Enabled',
        description:
          '',
        Component: CheckBox,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  }
}

export default schema
