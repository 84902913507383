import getMetaStoresFetch from '../../../api/table/getMetaStoresFetch'
import useFetch from '../../../hooks/useFetch'
import {map, path, pathOr, pipe, propOr, toPairs} from 'ramda'
import {EMPTY_OBJECT, EMPTY_STRING} from '../../../constants'
import getCatalogsFetch from '../../../api/table/getCatalogsFetch'
import getDatabasesFetch from '../../../api/table/getDatabasesFetch'
import getTablesFetch from '../../../api/table/getTablesFetch'
import getColumnsFetch from '../../../api/table/getColumnsFetch'
import {useCallback, useEffect, useMemo, useState} from 'react'
import ExpandListButton from '../../../components/ExpandListButton'
import RButton from '../../../components/RButton'
import {useDispatch, useSelector} from 'react-redux'
import {setSelectedElement} from '../../../reducers/tableExplorer'

const padding = 28

const ColumnElement = ({name, type}) => {
  return (
    <>
      <RButton name={name} padding={padding * 5}>
        {type}
      </RButton>
    </>
  )
}

const TableElement = ({
  metaStoreId,
  metaStoreName,
  catalogId,
  catalogName,
  databaseId,
  databaseName,
  tableId,
  tableName,
}) => {
  const [expanded, setExpanded] = useState(false)
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))

  const {data = EMPTY_STRING, error} = useFetch(
    getColumnsFetch,
    {},
    {
      params: [
        {key: 'metaStoreId', value: metaStoreId},
        {key: 'catalogId', value: catalogId},
        {
          key: 'databaseId',
          value: databaseId,
        },
        {
          key: 'tableId',
          value: tableId,
        },
      ],
    }
  )

  const toggleExpand = useCallback((event) => {
    event.stopPropagation()
    setExpanded((expanded) => !expanded)
  }, [])

  const schema = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'schema'),
        toPairs,
        map(([name, rest]) => ({name, ...rest}))
      )(data),
    [data]
  )

  const dispatch = useDispatch()
  const toggleChosen = useCallback((event) => {
    event.stopPropagation()
    dispatch(
      setSelectedElement({
        type: 'table',
        metaStoreId,
        metaStoreName,
        catalogId,
        catalogName,
        databaseId,
        databaseName,
        tableId,
        tableName,
      })
    )
  }, [])

  return (
    <>
      <RButton
        name={tableId}
        padding={padding * 3}
        icon={'table'}
        active={selectedElement?.type === 'table' && selectedElement?.tableId === tableId}
        onClick={toggleChosen}
        StartComponent={ExpandListButton}
        startComponentProps={{
          expanded,
          onClick: toggleExpand,
        }}
      />
      {expanded && map((data) => <ColumnElement {...data}></ColumnElement>, schema)}
    </>
  )
}

const TablesList = ({
  metaStoreId,
  metaStoreName,
  catalogId,
  catalogName,
  databaseId,
  databaseName,
  fetchOptions,
  setPending,
}) => {
  const {data = EMPTY_STRING, isLoading} = useFetch(getTablesFetch, fetchOptions, {
    params: [
      {key: 'metaStoreId', value: metaStoreId},
      {key: 'catalogId', value: catalogId},
      {
        key: 'databaseId',
        value: databaseId,
      },
    ],
  })

  useEffect(() => {
    setPending(isLoading)
  }, [isLoading])

  return (
    <>
      {map(
        (id) => (
          <TableElement
            metaStoreId={metaStoreId}
            metaStoreName={metaStoreName}
            catalogId={catalogId}
            catalogName={catalogName}
            databaseId={databaseId}
            databaseName={databaseName}
            tableId={id}
            tableName={id}
          />
        ),
        data
      )}
    </>
  )
}

const DatabaseElement = ({metaStoreId, metaStoreName, catalogId, databaseId, fetchOptions}) => {
  const [expanded, setExpanded] = useState(false)
  const [pending, setPending] = useState(false)
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))

  const dispatch = useDispatch()
  const toggleChosen = useCallback((event) => {
    event.stopPropagation()
    dispatch(
      setSelectedElement({
        type: 'database',
        metaStoreId,
        metaStoreName,
        catalogId,
        catalogName: catalogId,
        databaseId,
        databaseName: databaseId,
      })
    )
  }, [])
  return (
    <>
      <RButton
        name={databaseId}
        padding={padding * 2}
        icon={'database'}
        onClick={toggleChosen}
        active={selectedElement?.type === 'database' && selectedElement?.databaseId === databaseId}
        StartComponent={ExpandListButton}
        startComponentProps={{
          expanded,
          pending,
          onClick: (event) => {
            event.stopPropagation()
            setExpanded(!expanded)
          },
        }}
      />
      {expanded && (
        <TablesList
          metaStoreId={metaStoreId}
          metaStoreName={metaStoreName}
          catalogId={catalogId}
          catalogName={catalogId}
          databaseId={databaseId}
          databaseName={databaseId}
          fetchOptions={fetchOptions}
          setPending={setPending}
        />
      )}
    </>
  )
}

const DatabaseList = ({metaStoreId, metaStoreName, catalogId, fetchOptions, setPending}) => {
  const {data = EMPTY_STRING, isLoading} = useFetch(getDatabasesFetch, fetchOptions, {
    params: [
      {key: 'metaStoreId', value: metaStoreId},
      {key: 'catalogId', value: catalogId},
    ],
  })

  useEffect(() => {
    setPending(isLoading)
  }, [isLoading])

  return (
    <>
      {map(
        (id) => (
          <DatabaseElement
            metaStoreId={metaStoreId}
            metaStoreName={metaStoreName}
            catalogId={catalogId}
            databaseId={id}
            fetchOptions={fetchOptions}
          />
        ),
        data
      )}
    </>
  )
}

const CatalogElement = ({catalogId, metaStoreId, fetchOptions, metaStoreName}) => {
  const [expanded, setExpanded] = useState(false)
  const [pending, setPending] = useState(false)
  const dispatch = useDispatch()
  const toggleChosen = useCallback((event) => {
    event.stopPropagation()
    dispatch(
      setSelectedElement({
        type: 'catalog',
        catalogId,
        catalogName: catalogId,
        metaStoreId,
        metaStoreName,
      })
    )
  }, [])

  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))

  const toggleExpand = useCallback((event) => {
    event.stopPropagation()
    setExpanded((expanded) => !expanded)
  }, [])

  return (
    <>
      <RButton
        name={catalogId}
        padding={padding}
        icon={'directory'}
        active={selectedElement?.type === 'catalog' && selectedElement?.catalogId === catalogId}
        onClick={toggleChosen}
        StartComponent={ExpandListButton}
        startComponentProps={{
          expanded,
          pending,
          onClick: toggleExpand,
        }}
      ></RButton>

      {expanded && (
        <DatabaseList
          metaStoreId={metaStoreId}
          metaStoreName={metaStoreName}
          catalogId={catalogId}
          fetchOptions={fetchOptions}
          setPending={setPending}
        />
      )}
    </>
  )
}

const CatalogsList = ({metaStoreId, metaStoreName, fetchOptions, setPending}) => {
  const {data = EMPTY_STRING, isLoading} = useFetch(getCatalogsFetch, fetchOptions, {
    params: [{key: 'metaStoreId', value: metaStoreId}],
  })

  useEffect(() => {
    setPending(isLoading)
  }, [isLoading])

  return (
    <>
      {map(
        (data) => (
          <CatalogElement
            catalogId={data}
            metaStoreId={metaStoreId}
            metaStoreName={metaStoreName}
            fetchOptions={fetchOptions}
          />
        ),
        data
      )}
    </>
  )
}

const MetaStoreElement = ({type, metaStoreName, metaStoreId, fetchOptions}) => {
  const [expanded, setExpanded] = useState(false)
  const [pending, setPending] = useState(false)

  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))

  const dispatch = useDispatch()
  const toggleChosen = useCallback(
    (event) => {
      event.stopPropagation()
      dispatch(setSelectedElement({type: 'metaStore', metaStoreName, metaStoreId, kind: type}))
    },
    [metaStoreName, metaStoreId, type]
  )

  const toggleExpand = useCallback((event) => {
    event.stopPropagation()
    setExpanded((expanded) => !expanded)
  }, [])

  return (
    <>
      <RButton
        name={metaStoreName}
        icon={'server'}
        onClick={toggleChosen}
        active={selectedElement?.type === 'metaStore' && selectedElement?.metaStoreId === metaStoreId}
        StartComponent={ExpandListButton}
        startComponentProps={{
          expanded,
          pending,
          onClick: toggleExpand,
        }}
      >
        <div>{type}</div>
      </RButton>
      {expanded && (
        <CatalogsList
          metaStoreId={metaStoreId}
          metaStoreName={metaStoreName}
          fetchOptions={fetchOptions}
          setPending={setPending}
        />
      )}
    </>
  )
}

const List = ({fetchOptions}) => {
  const {data = EMPTY_STRING, isLoading} = useFetch(getMetaStoresFetch, fetchOptions)
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))

  const dispatch = useDispatch()

  const {id: metaStoreId, name: metaStoreName} = pathOr(EMPTY_OBJECT, ['content', 0], data)

  useEffect(() => {
    if (!selectedElement && metaStoreId) {
      dispatch(setSelectedElement({type: 'metaStore', metaStoreId, metaStoreName}))
    }
  }, [metaStoreId, selectedElement])

  return (
    <div style={{overflow: 'auto'}}>
      {map(
        ({id, type, name}) => (
          <MetaStoreElement
            metaStoreName={name}
            type={type}
            metaStoreId={id}
            fetchOptions={fetchOptions}
          />
        ),
        data?.content || []
      )}
    </div>
  )
}

export default List
