import {Icon} from 'frontcore'
import React from 'react'
import {colorMap} from '../../miniatures'

const typeMap = {
  cluster: 'cluster',
  group: 'service',
  schedule: 'schedule',
  job: 'job',
  request: 'request',
  timelineJob: 'job',
}

const iconMap = {
  cluster: 'cluster',
  service: 'box',
  schedule: 'clock',
  job: 'database',
  request: 'table',
  metastore: 'server',
  database: 'database',
  table: 'table',
  catalog: 'directory',
  storage: 'server',
  bucket: 'trash',
  folder: 'directory',
  root: 'home',
  timelineJob: 'table',
  timelineStage: 'table',
  executor: 'gear',
  'kubernetes-pod': 'kubernetes',
  'spark-submit-process': 'spark',
}

const ResourceIcon = ({type}) => {
  return (
    <div
      style={{
        background: colorMap[type] || 'black',
        width: 64,
        minWidth: 64,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
      }}
    >
      <Icon icon={iconMap[type]} size={40} color={'white'} />
    </div>
  )
}
export default ResourceIcon
