import {Icon, NavigationButton} from 'frontcore'
import React from 'react'
import styled from 'styled-components'
import {createTransitions, toBoolean} from '../../helpers'
import {
  ILUM_AIRFLOW_ENABLED,
  ILUM_DATA_ENABLED,
  ILUM_GITEA_ENABLED,
  ILUM_JUPYTER_ENABLED,
  ILUM_MLFLOW_ENABLED,
  ILUM_SUPERSET_ENABLED,
} from '../../helpers/runtimeEnv'
import {Link, useLocation} from 'react-router-dom'
import {path, startsWith, toUpper} from 'ramda'
import {useSelector} from 'react-redux'
import UserDropdown from '../userDropdown'
import {HORIZONTAL} from '../../constants'
import VerticalLayout from './VerticalLayout'
import HorizontalLayout from './HorizontalLayout'
import Chip from "../Chip";
import UserIcon from "../Icons/UserIcon";

const isExpanded = (width, foldable = true) => (foldable ? width > 200 : true)

const subMenuButtonProps = {
  fullWidth: true,
  rounding: false,
  size: 'large',
}

const menuButtonProps = {
  size: 'large',
  fullWidth: true,
}

const Text = styled.div`
  color: ${({theme}) => theme.palette.text.tertiary};
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 16.5px */
  letter-spacing: 0.4px;
`

const MenuButton = ({
  to,
  icon,
  configuration,
  label,
  buttonProps,
  activeLabel,
  ContextContentComponent,
  expanded = true,
  textWrap = true,
  orientation,
    Component,
    componentProps
}) => {
  const {pathname} = useLocation()

  return (
    <StyledLink to={to}>
      <div
        style={{
          display: 'flex',
          flexDirection: orientation === 'horizontal' ? 'column' : 'row',
          alignItems: 'center',
            width: '100%',
          gap: 8,
        }}
      >
        <NavigationButton
          configuration={configuration}
          ContextContentComponent={ContextContentComponent}
          active={startsWith(activeLabel || to, pathname)}
          buttonProps={{
            Component:  () => orientation ==='vertical' && <Text
                style={{
                    textDecoration: 'none',
                    fontSize: 12,
                    fontFamily: 'Roboto',
                    textAlign: 'center',
                    textWrap: textWrap ? 'auto' : 'nowrap',
                }}
            >
                {label}
            </Text>,

            StartComponent: Component ? Component : Icon,
            startComponentProps: componentProps ? componentProps : {icon, size: 24},
            justifyContent: 'center',
              square: orientation === 'horizontal',
            ...buttonProps,
          }}
        />

          {orientation ==='horizontal' &&  <Text
          style={{
            textDecoration: 'none',
            fontSize: 12,
            fontFamily: 'Roboto',
            textAlign: 'center',
            textWrap: textWrap ? 'auto' : 'nowrap',
          }}
        >
          {label}
        </Text>}
      </div>
    </StyledLink>
  )
}

const Buttons = ({orientation}) => {
  const {pathname} = useLocation()
  return (
    <>
      <MenuButton
        to={'/workloads/list/services'}
        icon={'home'}
        label={'Workloads'}
        buttonProps={menuButtonProps}
        orientation={orientation}
        activeLabel={'/workloads'}
        ContextContentComponent={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',

            }}
          >
            <MenuButton
              to={'/workloads/list/clusters'}
              label={'Clusters'}
              icon={'cluster'}
              orientation={'vertical'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/services'}
              label={'Services'}
              icon={'box'}
              orientation={'vertical'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/schedules'}
              label={'Schedules'}
              icon={'clock'}
              orientation={'vertical'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/jobs'}
              label={'Jobs'}
              icon={'database'}
              orientation={'vertical'}
              buttonProps={subMenuButtonProps}
            />
            <MenuButton
              to={'/workloads/list/requests'}
              label={'Requests'}
              icon={'table'}
              orientation={'vertical'}
              buttonProps={subMenuButtonProps}
            />
          </div>
        )}
      />
      <MenuButton
        orientation={orientation}
        to={'/history'}
        icon={'history'}
        label={'History Server'}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        orientation={orientation}
        to={'/data'}
        icon={'table-2'}
        label={'Table Explorer'}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        orientation={orientation}
        to={'/file-explorer'}
        icon={'directory-2'}
        label={'File Explorer'}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        orientation={orientation}
        to={'/sql'}
        icon={'database'}
        label={'SQL'}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        orientation={orientation}
        to={'/ilum-lineage/list/jobs'}
        icon={'Vector'}
        label={'Lineage'}
        buttonProps={menuButtonProps}
        activeLabel={'/ilum-lineage'}
      />
      <MenuButton
        orientation={orientation}
        to={'/documentation'}
        icon={'info'}
        label={'User Guides'}
        buttonProps={menuButtonProps}
      />
      <MenuButton
        orientation={orientation}
        to={'/security/list/users'}
        icon={'shield-lock'}
        label={'Security'}
        activeLabel={'/security'}
        buttonProps={menuButtonProps}
        ContextContentComponent={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',

            }}
          >
            <MenuButton
              to={'/security/list/users'}
              label={'Users'}
              buttonProps={subMenuButtonProps}
              Component={  UserIcon }
              orientation={'vertical'}
              componentProps={{children: 'U', color: '#FF8B96'}}
            />
            <MenuButton
              to={'/security/list/groups'}
              label={'Groups'}
              buttonProps={subMenuButtonProps}
              Component={Chip}
              orientation={'vertical'}
              componentProps={{children: 'G', type: 'GROUP'}}
            />
            <MenuButton
              to={'/security/list/roles'}
              label={'Roles'}
              buttonProps={subMenuButtonProps}
              Component={Chip}
              orientation={'vertical'}
              componentProps={{children: 'R', type: 'ROLE'}}
            />
          </div>
        )}
      />
      <MenuButton
        orientation={orientation}
        to={'/settings/tooltip'}
        icon={'gear'}
        label={'Settings'}
        buttonProps={menuButtonProps}
        activeLabel={'/settings'}
        // ContextContentComponent={() => (
        //   <div
        //     style={{
        //       display: 'flex',
        //       flexDirection: 'column',
        //     }}
        //   >
        //
        //     <MenuButton
        //       to={'/settings/tooltip'}
        //       label={'Tooltip'}
        //       buttonProps={subMenuButtonProps}
        //     />
        //     <MenuButton to={'/settings/theme'} label={'Theme'} buttonProps={subMenuButtonProps} />
        //     <MenuButton
        //       to={'/settings/license'}
        //       label={'License'}
        //       buttonProps={subMenuButtonProps}
        //     />
        //     <MenuButton
        //       to={'/settings/metrics'}
        //       label={'Metrics'}
        //       buttonProps={subMenuButtonProps}
        //     />
        //   </div>
        // )}
      />

      <MenuButton
        orientation={orientation}
        to={'/modules'}
        icon={'modules'}
        label={'Modules'}
        buttonProps={menuButtonProps}
        ContextContentComponent={() => (
          <div
              style={{
                  display: 'flex',
                  flexDirection: 'column',

              }}
          >
            {toBoolean(ILUM_DATA_ENABLED) && (
              <MenuButton
                to={'/minio'}
                label={'Minio'}
                icon={'minio'}
                orientation={'vertical'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_JUPYTER_ENABLED) && (
              <MenuButton
                to={'/notebook'}
                label={'Jupyter'}
                icon={'jupyter'}
                orientation={'vertical'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_AIRFLOW_ENABLED) && (
              <MenuButton
                to={'/airflow'}
                label={'Airflow'}
                icon={'apacheairflow'}
                orientation={'vertical'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_GITEA_ENABLED) && (
              <MenuButton
                to={'/gitea'}
                label={'Gitea'}
                icon={'git3'}
                orientation={'vertical'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_MLFLOW_ENABLED) && (
              <MenuButton
                to={'/mlflow'}
                label={'MLflow'}
                icon={'mlflow'}
                orientation={'vertical'}
                buttonProps={subMenuButtonProps}
              />
            )}
            {toBoolean(ILUM_SUPERSET_ENABLED) && (
              <MenuButton
                to={'/superset'}
                label={'Superset'}
                icon={'superset'}
                orientation={'vertical'}
                buttonProps={subMenuButtonProps}
              />
            )}
            <MenuButton
              to={'/modules'}
              label={'Overview'}
              icon={'project'}
              orientation={'vertical'}
              buttonProps={subMenuButtonProps}
            />
          </div>
        )}
      />
    </>
  )
}

const Image = styled.img`
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 300px;
`

const StyledLink = styled(Link)`
  outline: none;
  text-decoration: none;
`

const Root = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.palette['surface-primary']};
  display: flex;
  padding: 16px 8px;
  box-sizing: border-box;
`

const LogoRoot = styled.div`
  height: 64px;

  width: 64px;
  min-width: 64px;
  box-sizing: border-box;
  padding-left: 18px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const logoMap = {
  dark: '/logoWhite.svg',
  light: '/logo.svg',
}

const Logo = () => {
  const mode = useSelector(path(['settings', 'settings', 'darkMode']))
  return (
    <LogoRoot>
      <img style={{width: 150, height: 200}} src={logoMap[mode ? 'dark' : 'light']} alt="ilum" />
    </LogoRoot>
  )
}

const Navbar = ({orientation}) => {
  const aaa = (
    <div
      style={{
        opacity: 0.6,
        fontSize: 12,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {isExpanded(200) ? 'Copyright ©2024 Ilum ver. 6.2.0' : 'v6.2.0'}
    </div>
  )

  return orientation === HORIZONTAL ? (
    <VerticalLayout LogoComponent={Logo} ButtonsComponent={Buttons} UserComponent={UserDropdown} />
  ) : (
    <HorizontalLayout
      LogoComponent={Logo}
      ButtonsComponent={Buttons}
      UserComponent={UserDropdown}
    />
  )
}

export default Navbar
