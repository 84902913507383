import {useListRefreshFuture} from '../../../WorkloadsView/hooks'
import ListLayout from '../../../../components/layouts/listLayout'
import Filters from '../../../WorkloadsView/filters'
import {Button, Icon, Input, Select} from 'frontcore'
import React from 'react'
import RolesList from './RolesList'
import getRoles from '../../../../api/security/getRoles'
import {useNavigate} from 'react-router-dom'
import usePending from "../../../../hooks/usePending";
import {isNotEmpty} from "../../../../helpers";
import RolesBatchButtons from "../../Components/RolesButtons/RolesBatchButtons";
import {EMPTY_ARRAY} from "../../../../constants";

const schema = [
    {
        id: 'search',
        Component: Input,
        defaultValue: '',
        props: {
            StartComponent: Icon,
            startComponentProps: {
                size: 16,
                icon: 'search',
            },
            placeHolder: 'Search',
            topLabelProps: {
                children: 'Users Search',
            },
            label: 'Users Search',
        },
    },
    {
        id: 'state',
        Component: Select,
        defaultValue: EMPTY_ARRAY,
        props: {
            label: 'State',
            value: [],
            options: ['ENABLED', 'DISABLED'],
            multiSelect: true,
            buttonProps: {
                width: '200px',
            },
        },
    },
]


const SecurityRolesListView = () => {
    const listHandler = useListRefreshFuture(
        getRoles,
        'security',
        'roles',
        {
            page: 0,
            size: 10,
            sort: {
                creationTime: 'desc',
            },
            filter: {
                groupStates: ['ACTIVE'],
            },
        }
    )

    const {selected} = listHandler;

    const navigate = useNavigate()

    const pending = usePending(
        ['security', 'data'],
        ['roles', 'createRole', 'deleteRole', 'enableRole', 'disableRole']
    )
    return (
        <ListLayout
            batchButtonsNode={  isNotEmpty(selected) && (
                <RolesBatchButtons {...listHandler} />
            )}
            filtersNode={
                <Filters
                    name={'roles'}
                    schema={schema}
                    pending={pending}
                />
            }
            createButtonNode={
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => navigate('/security/create/role')}
                        EndComponent={Icon}
                        endComponentProps={{
                            icon: 'plus',
                            size: 18,
                            color: 'white',
                        }}
                    >
                        Add Role
                    </Button>
                </div>
            }
            contentNode={
                <RolesList
                    pending={pending}
                    listHandler={listHandler}
                />
            }
        />
    )
}

export default SecurityRolesListView
