import React, {useCallback, useMemo} from 'react'
import ToolTip from '../../../../components/toolTip'
import {DropDownButton, Icon} from 'frontcore'
import styled from 'styled-components'
import ToolTipContentLayout from '../../../../components/layouts/toolTip/toolTipContentLayout'
import DeleteContentComponent from '../../../WorkloadsView/Components/ClusterButtons/deleteContentComponent'

const Root = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const ActivityButtons = ({
  id,
  name,
  onDelete,

  texts = true,
  pending = false,
  disabled = false,
  data,
}) => {
  const handleOnDelete = useCallback(
    () => onDelete({id}),
    [id, onDelete]
  )

  const size = 24
  const baseButtonsProps = useMemo(
    () => ({
      variant: 'text',
      tabIndex: -1,
      skeleton: pending,
    }),
    [pending]
  )

  const squareButtonProps = useMemo(
    () =>
      texts
        ? {square: false}
        : {square: true, justifyContent: 'center'},
    [texts]
  )

  const deleteButtonProps = useMemo(
    () =>
      texts
        ? {
            children: 'Delete',
            StartComponent: Icon,
            startComponentProps: {icon: 'trash', size},
          }
        : {
            Component: Icon,
            componentProps: {icon: 'trash', size},
          },
    [texts]
  )

  return (
    <Root>
      {onDelete && (
        <ToolTip
          content={
            <ToolTipContentLayout>
              Delete
            </ToolTipContentLayout>
          }
        >
          <DropDownButton
            ContentComponent={DeleteContentComponent}
            contentComponentProps={{
              name,
              onDelete: handleOnDelete,
            }}
            buttonProps={{
              ...baseButtonsProps,
              ...squareButtonProps,
              ...deleteButtonProps,
              disabled,
            }}
          >
            Delete
          </DropDownButton>
        </ToolTip>
      )}
    </Root>
  )
}

export default ActivityButtons
