import {map, omit, path, pipe, propOr, toPairs} from 'ramda'
import React, {useEffect, useMemo, useState} from 'react'
import {Tabs} from 'frontcore'
import DetailsLayout from '../detailsLayout'
import useFuture from '../../../hooks/useFuture'
import {fork} from 'fluture'
import {useDispatch, useSelector} from 'react-redux'
import getDatabase from '../../../api/table/getDatabase'
import {isNotEmpty} from '../../../helpers'
import Configuration from '../../../components/configuration'
import NoResultFound from '../../../components/NoResultFound'
import {EMPTY_OBJECT, EMPTY_STRING} from '../../../constants'
import DetailsHeader from '../../../components/DetailsHeader'
import ResourceIcon from '../../../components/Icons/ResourceIcon'
import useFetch from '../../../hooks/useFetch'
import {setSelectedElement} from '../../../reducers/tableExplorer'
import getTablesFetch from '../../../api/table/getTablesFetch'
import Json from '../../../components/Json'
import RButton from '../../../components/RButton'

const Tables = () => {
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))

  const {metaStoreId, catalogId, databaseId} = selectedElement

  const {data = EMPTY_STRING, isLoading} = useFetch(
    getTablesFetch,
    {},
    {
      params: [
        {key: 'metaStoreId', value: metaStoreId},
        {key: 'catalogId', value: catalogId},
        {key: 'databaseId', value: databaseId},
      ],
    }
  )
  const dispatch = useDispatch()
  return (
    <div style={{display: 'flex', overflow: 'hidden', gap: 12, padding: 12, flexWrap: 'wrap'}}>
      {map(
        (id) => (
          <RButton
            icon={'table'}
            name={id}
            onClick={() =>
              dispatch(
                setSelectedElement({
                  ...selectedElement,
                  type: 'table',
                  tableId: id,
                  tableName: id,
                })
              )
            }
          />
        ),
        data
      )}
    </div>
  )
}

const DatabaseDetails = () => {
  const selectedElement = useSelector(path(['tableExplorer', 'selectedElement']))

  const [tab, setTab] = useState('tables')

  const {name, kind, metaStoreId, catalogId, databaseId} = selectedElement

  const getDatabaseFuture = useFuture(getDatabase)

  const details = useSelector(path(['data', 'data', 'database', 'response']))

  const extendedDetailsData = pipe(
    omit(['parameters', 'metastoreId', 'catalogId', 'catalogName']),
    toPairs,
    map((a) => ({key: a[0], value: a[1]}))
  )(details || [])
  const tabsContent = (
    <Tabs
      value={tab}
      onChange={setTab}
      options={[
        {
          id: 'tables',
          children: 'Tables',
        },
        {
          id: 'parameters',
          children: 'Parameters',
        },
        {
          id: 'raw',
          children: 'Raw',
        },
      ]}
    />
  )

  useEffect(() => {
    fork(() => {})(() => {})(
      getDatabaseFuture({
        params: [
          {
            key: 'metaStoreId',
            value: metaStoreId,
          },
          {key: 'catalogId', value: catalogId},
          {key: 'id', value: databaseId},
        ],
      })
    )
  }, [metaStoreId, catalogId, databaseId])

  const configurationData = useMemo(
    () =>
      pipe(
        propOr(EMPTY_OBJECT, 'parameters'),
        toPairs,
        map((a) => ({key: a[0], value: a[1]}))
      )(details),
    [details]
  )

  const infoContent = (
    <DetailsHeader
      icon={<ResourceIcon type={'database'} />}
      name={details?.name}
      id={details?.type}
      pending={false}
      data={extendedDetailsData}
    />
  )

  const listContent = isNotEmpty(configurationData) ? (
    <div style={{overflow: 'hidden', padding: 16}}>
      <Configuration
        data={configurationData}
        schema={[
          {
            id: 'key',
            label: 'Key',
            copy: true,
          },
          {
            id: 'value',
            label: 'Value',
            copy: true,
            obfuscate: true,
          },
        ]}
      />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <NoResultFound title={'No Data'} text={'This Metastore have no configuration'} />
    </div>
  )

  const bottomContent = (
    <>
      {tab === 'tables' && <Tables />}
      {tab === 'parameters' && listContent}
      {tab === 'raw' && (
        <div style={{padding: 16}}>
          <Json src={details} />
        </div>
      )}
    </>
  )

  return (
    <DetailsLayout
      infoContent={infoContent}
      tabsContent={tabsContent}
      bottomContent={bottomContent}
    />
  )
}

export default DatabaseDetails
