import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
    gap: 16px;
  overflow: hidden;
  align-items: center;
 
  padding-right: 20px;
  box-sizing: border-box;
`

const VerticalLayout = ({
  LogoComponent,
  ButtonsComponent,
  UserComponent,
}) => {
  return (
    <Root>
      <LogoComponent />
      <div
        style={{
          display: 'flex',
            gap: 8,
              alignItems: 'center',

        }}
      >
        <ButtonsComponent
            orientation={'vertical'}
        />
      </div>
      <div style={{flex: 1}}></div>
      <UserComponent expanded={true} />
    </Root>
  )
}

export default VerticalLayout
