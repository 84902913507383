import {Button, Icon} from 'frontcore'
import {useSelector} from 'react-redux'
import {path} from 'ramda'
import React from 'react'
import styled from 'styled-components'
import {createTransitions} from '../../helpers'
import Flex from '../../components/Flex'
import loremIpsum from '../../helpers/loremIpsum'

const lightTheme = {
  fontColorPrimary: '#5e6c80',
  fontColorSecondary: '#6e7b8a',
  surfacePrimary: 'rgb(255,255,255)',
  surfaceSecondary: 'rgb(239,239,239)',
  blue: '#01bfd3',
  blue01: 'rgba(28,229,250,0.1)',
  yellow: '#ffb400',
  yellow01: 'rgba(255,189,24,0.1)',
  def: '#adb7c5',
  def01: 'rgba(173,183,197,0.1)',
  red: '#dc003a',
  red01: 'rgba(255,23,84,0.1)',
  c1: '#f14d74',
  c2: '#a345ff',
  c3: '#5dc73a',
  c4: '#ffb400',
  c5: '#59cad7',
}

const darkTheme = {
  fontColorSecondary: '#6e7b8a',
  fontColorPrimary: '#9ca6b4',
  surfacePrimary: 'rgb(27,33,37)',
  surfaceSecondary: 'rgb(13,16,17)',
  blue: '#00b3c5',
  blue01: 'rgba(28,229,250,0.1)',
  yellow: '#e7a600',
  yellow01: 'rgba(255,189,24,0.1)',
  def: '#adb7c5',
  def01: 'rgba(173,183,197,0.1)',
  red: '#ff1754',
  red01: 'rgba(255,23,84,0.1)',
  c1: '#f14d74',
  c2: '#a345ff',
  c3: '#5dc73a',
  c4: '#ffb400',
  c5: '#59cad7',
}

const darkTheme1 = {
  fontColorSecondary: '#ffffff',
  fontColorPrimary: '#ffffff',
  surfacePrimary: '#293042',
  surfaceSecondary: '#202634',
  blue: '#00b3c5',
  blue01: 'rgba(28,229,250,0.1)',
  yellow: '#e7a600',
  yellow01: 'rgba(255,189,24,0.1)',
  def: '#adb7c5',
  def01: 'rgba(173,183,197,0.1)',
  red: '#ff1754',
  red01: 'rgba(255,23,84,0.1)',
  c1: '#f14d74',
  c2: '#a345ff',
  c3: '#5dc73a',
  c4: '#ffb400',
  c5: '#59cad7',
}

const rythm = 6
const radius = 2

const {
  fontColorSecondary,
  fontColorPrimary,
  surfacePrimary,
  surfaceSecondary,
  blue,
  blue01,
  yellow,
  yellow01,
  def,
  def01,
  red,
  red01,
  c1,
  c2,
  c3,
  c4,
  c5,
} = lightTheme

const Panel = styled.div`
  background: ${({background}) => background} ;
  border-radius: ${radius + 'px'};
`

const StateLabel = ({color, background, children, icon}) => {
  return (
    <div
      style={{
        // border: `1px solid ${color}`,
        padding: 6,
        alignItems: 'center',
        gap: 8,
        color: color,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        // background: background,
      }}
    >
      <div style={{width: 24}}>
        <Icon icon={'alert-contained'} size={18} color={color} />
      </div>
      {children}
    </div>
  )
}

const Row = ({activeNode}) => {



    const {
        fontColorSecondary,
        fontColorPrimary,
        surfacePrimary,
        surfaceSecondary,
        blue,
        blue01,
        yellow,
        yellow01,
        def,
        def01,
        red,
        red01,
        c1,
        c2,
        c3,
        c4,
        c5,
    } = useTheme()

    return (
    <Flex
      alignItems={'center'}
      justifyContent={'flex-start'}
      gap={4}
      style={{
        padding: '4px 20px',
          marginRight: 1,
          background: surfacePrimary
      }}
    >
      <Check />
      <Flex flex={1} />
      <Flex flex={1}>
        <HeaderButtonLower>default</HeaderButtonLower>
      </Flex>
      <Flex flex={2}>
        <HeaderButtonLower>23234dssdz-23sza</HeaderButtonLower>
      </Flex>
      <Flex flex={1}>
        <HeaderButtonLower>JOB</HeaderButtonLower>
      </Flex>
      <Flex flex={1}>
        <HeaderButtonLower>Python</HeaderButtonLower>
      </Flex>
      <Flex flex={1}>
        <HeaderButtonLower>{activeNode}</HeaderButtonLower>
      </Flex>
      <Flex flex={1}>
        <HeaderButtonLower>10</HeaderButtonLower>
      </Flex>
      <Flex flex={1}>
        <HeaderButtonLower>20</HeaderButtonLower>
      </Flex>
      <Flex style={{flex: 1}}>
        <HeaderButtonLower>Yesterday</HeaderButtonLower>
      </Flex>
    </Flex>
  )
}

const RButton = ({icon, name, color, size, background, additional}) => {
  return (
    <Flex
      style={{
        gap: 12,
        alignItems: 'center',
      }}
    >
      <Flex
        style={{
          background: color,
          width: 36,
          borderRadius: 1,
          height: 36,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon size={24} color={surfaceSecondary} icon={icon} />
      </Flex>
      <div>{name}</div>
    </Flex>
  )
}

const Check = () => {

    const {
        fontColorSecondary,
        fontColorPrimary,
        surfacePrimary,
        surfaceSecondary,
        blue,
        blue01,
        yellow,
        yellow01,
        def,
        def01,
        red,
        red01,
        c1,
        c2,
        c3,
        c4,
        c5,
    } = useTheme()
  return (
    <div
      style={{
        width: 18,
        height: 18,
        // border: '1px solid #525b67',

        borderRadius: 2,
        backgroundColor: surfaceSecondary,
      }}
    ></div>
  )
}

const HeaderButtonLower = ({children}) => {
  return (
    <Flex
      style={{
        color: fontColorPrimary,
        fontSize: 12,
        // fontWeight: 800,
        height: 32,
        alignItems: 'center',
        paddingLeft: 12,
        paddingRight: 12,
      }}
    >
      {children}
    </Flex>
  )
}

const HeaderButton = ({children}) => {
  return (
    <Flex
      style={{
        color: fontColorPrimary,
        fontSize: 12,
        fontWeight: 800,
        textTransform: 'uppercase',
        height: 32,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 12,
        paddingRight: 12,
      }}
    >
      {children}
    </Flex>
  )
}

const Input = ({placeholder, label}) => {

    const {
        fontColorSecondary,
        fontColorPrimary,
        surfacePrimary,
        surfaceSecondary,
        blue,
        blue01,
        yellow,
        yellow01,
        def,
        def01,
        red,
        red01,
        c1,
        c2,
        c3,
        c4,
        c5,
    } = useTheme()
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div
        style={{
          color: fontColorPrimary,
          padding: 5,
          fontSize: 10,
          fontWeight: 500,
          textTransform: 'uppercase',
        }}
      >
        {label}
      </div>
      <div
        style={{
          height: 36,
          width: 300,
          background: surfaceSecondary,
          borderRadius: 2,
          color: fontColorSecondary,
          display: 'flex',
          fontSize: 14,
          alignItems: 'center',
          paddingLeft: 12,
          boxSizing: 'border-box',
        }}
      >

        {placeholder}
      </div>
    </div>
  )
}

const HealthBar = ({value = 0, title, valueLabel, percent, color}) => {
    const {
        fontColorSecondary,
        fontColorPrimary,
        surfacePrimary,
        surfaceSecondary,
        blue,
        blue01,
        yellow,
        yellow01,
        def,
        def01,
        red,
        red01,
        c1,
        c2,
        c3,
        c4,
        c5,
    } = useTheme()
  return (
    <Flex
      flexDirection={'column'}
      style={{
        gap: 6,

      }}
    >
      <Flex
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            fontSize: 10,
            fontWeight: 800,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontSize: 10,
            fontWeight: 800,
            textTransform: 'uppercase',
          }}
        >
          {valueLabel}
        </div>
        <div
          style={{
            fontSize: 10,
            fontWeight: 800,
            textTransform: 'uppercase',
          }}
        >
          {percent}
        </div>
      </Flex>
      <div
        style={{
          height: 6,
          width: '100%',
          background: surfaceSecondary,

          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 2,
        }}
      >
        <div
          style={{
            flex: value,

            background: color,
            height: 2,
          }}
        ></div>
        <div style={{flex: 1}}></div>
      </div>
    </Flex>
  )
}

const MetricChart = ({title, description, background, color, value, unit, children, stateNode}) => {


    const {
        fontColorSecondary,
        fontColorPrimary,
        surfacePrimary,
        surfaceSecondary,
        blue,
        blue01,
        yellow,
        yellow01,
        def,
        def01,
        red,
        red01,
        c1,
        c2,
        c3,
        c4,
        c5,
    } = useTheme()


    return <Panel
        background={surfacePrimary}
        style={{
            // borderColor: background,
            // borderStyle: 'solid',
            // borderWidth: 1,

            width: 200,
            flex: 1,

            color: color || fontColorPrimary,
            fontFamily: 'Inter',
            padding: 10,

            position: 'relative',
            boxSizing: 'border-box',
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                position: 'relative',
            }}
        >
            <div
                style={{
                    fontSize: 11,
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    display: 'flex',
                }}
            >
                {title}
            </div>
            <div style={{fontSize: 22, fontWeight: 600}}>
                {value}
                <span style={{fontSize: 16}}>{unit}</span>
            </div>

            <div style={{position: 'absolute', top: 10, right: 10}}>{children}</div>
            <div style={{position: 'absolute', right: 0, top: 0}}> {stateNode}</div>
        </div>
    </Panel>
}

const logoMap = {
  dark: '/logo.svg',
  light: '/logo.svg',
}

const LogoRoot = styled.div`
  display: flex;
  align-items: center;
  width: ${({expanded}) => (expanded ? '200px' : '30px')};
  transition: ${createTransitions(['width'])};
`

const Logo = ({expanded = true}) => {
  const mode = useSelector(path(['settings', 'settings', 'darkMode']))
  return (
    <LogoRoot expanded={expanded}>
      <img src={logoMap[mode ? 'dark' : 'light']} alt="ilum" />
    </LogoRoot>
  )
}

const useTheme = ()  => {
    const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))
    return darkMode ? darkTheme1 : lightTheme
}

const ThemeView = () => {




    const {
        fontColorSecondary,
        fontColorPrimary,
        surfacePrimary,
        surfaceSecondary,
        blue,
        blue01,
        yellow,
        yellow01,
        def,
        def01,
        red,
        red01,
        c1,
        c2,
        c3,
        c4,
        c5,
    } = useTheme()


    return (
    <Flex flexDirection={'column'} flex={1} style={{background: surfaceSecondary}} >
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        style={{
          height: 64,
         background: surfacePrimary,

          boxSizing: 'border-box',
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Logo />

        <div style={{position: 'relative'}}>
          <Input label={'Global Search'} placeholder={'Search'} />
          <Flex
            flexDirection={'column'}
            gap={1}
            style={{
              position: 'absolute',
              right: 0,
              borderRadius: 2,
              top: 60,
              width: 400,

              background: surfacePrimary,
              borderStyle: 'solid',
              borderTopWidth: 1,
              borderBottomWidth: 5,
              borderLeftWidth: 2,
              borderRightWidth: 2,
              borderColor: surfaceSecondary,
              padding: 10,
            }}
          >
            <RButton icon={'cluster'} name={'default'} color={c1} />
            <RButton icon={'clock'} name={'default'} color={c2} />
            <RButton icon={'box'} name={'default'} color={c3} />
            <RButton icon={'box'} name={'default'} color={c4} />
            <RButton icon={'cluster'} name={'default'} color={c5} />
          </Flex>
        </div>
      </Flex>
      <Flex
        flex={1}
        flexDirection={'column'}
        gap={2}
        style={{
          padding: 12,
        }}
      >
        <Flex gap={2} alignItems={'center'}>
          <Button
            variant={'text'}
            StartComponent={() => <RButton size={'small'} icon={'cluster'} color={c1} />}
          >
            Clusters
          </Button>
          <Button variant={'text'} StartComponent={() => <RButton icon={'box'} color={c4} />}>
            Services
          </Button>
          <Button variant={'text'} StartComponent={() => <RButton icon={'clock'} color={c2} />}>
            Schedules
          </Button>
          <Button variant={'text'} StartComponent={() => <RButton icon={'gear'} color={c3} />}>
            Jobs
          </Button>
          <Button variant={'text'} StartComponent={() => <RButton icon={'table'} color={c5} />}>
            Requests
          </Button>
        </Flex>

        <Flex style={{display: 'flex', gap: rythm}}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto auto auto',
              gridGap: rythm,
            }}
          >
            <MetricChart title={'All Jobs'} value={413} />
            <MetricChart title={'Ready Jobs'} value={380}></MetricChart>
            <MetricChart
              value={34}
              title={'Busy Jobs'}
              stateNode={<Icon icon={'alert-contained'} size={24} color={yellow} />}
              background={yellow}
              // color={'#0d2323'}
            ></MetricChart>
            <MetricChart value={0} title={'Failed Jobs'} />
            <MetricChart title={'All Schedules'} value={45} />
            <MetricChart title={'Ready Schedules'} value={40} />
            <MetricChart
              value={3}
              title={'Busy Schedules'}
              background={yellow}
              stateNode={<Icon icon={'alert-contained'} size={24} color={yellow} />}
              //  color={'#0d2323'}
            />
            <MetricChart
              value={2}
              title={'Failed Schedules'}
              background={red}
              stateNode={<Icon icon={'alert-contained'} size={24} color={red} />}
              //   color={'#0d2323'}
            />
            <MetricChart title={'All Services'} value={45} />
            <MetricChart title={'Ready Services'} value={2} />
            <MetricChart value={0} title={'Busy Services'} />
            <MetricChart value={0} title={'Failed Services'} />
          </div>

          <Panel
              background={surfacePrimary}
            style={{
              flex: 1,
                color: fontColorPrimary,
              display: 'flex',
              padding: 12,
              gap: 32,
              boxSizing: 'border-box',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: 32,
                height: 32,
                borderRadius: '50%',
              }}
            >
              <Icon icon={'arrow-left'} color={fontColorSecondary} />
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <div>default</div>

              <HealthBar
                color={red}
                value={0}
                title={'Cpu Usad'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
              <HealthBar
                color={yellow}
                value={3}
                title={'Cpu REQUESTED'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
              <HealthBar
                background={red}
                color={red}
                value={8}
                title={'MEMORY Used'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
              <HealthBar
                color={blue}
                value={0.2}
                title={'MEMORY REQUESTED'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <div>my cluster</div>

              <HealthBar
                color={blue}
                value={2}
                title={'Cpu Usad'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
              <HealthBar
                color={blue}
                value={3}
                title={'Cpu REQUESTED'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
              <HealthBar
                color={blue}
                value={4}
                title={'MEMORY Used'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
              <HealthBar
                color={blue}
                value={0.2}
                title={'MEMORY REQUESTED'}
                valueLabel={'0.5/400'}
                percent={'50%'}
              />
            </div>
            <div
              style={{
                width: 32,
                height: 32,
                borderRadius: '50%',
              }}
            >
              <Icon icon={'arrow-right'} color={fontColorSecondary} />
            </div>
          </Panel>
        </Flex>
        <Flex flex={1}  flexDirection={'column'} style={{marginRight: -8}}>
          <Flex
            gap={1}
            alignItems={'flex-end'}
            style={{
              padding: '10px 20px',
                background: surfacePrimary,
                marginRight: 8 + 1,
            }}
          >
            <Input placeholder={'Search'} label={'Services Search'} background={surfacePrimary}/>
            <Input placeholder={'Services States'} label={'Services States'} background={surfacePrimary}/>
            <Input placeholder={'Services Kinds'} label={'Services Kinds'} background={surfacePrimary}/>

            <Input placeholder={'Clusters'} label={'Clusters'} background={surfacePrimary}/>
            <HeaderButton>Reset Filters</HeaderButton>
            {/*<Button>New Service</Button>*/}
          </Flex>
          <div
            style={{
              border: `6px solid ${surfaceSecondary}`,
              width: '100%',
            }}
          ></div>
          <Flex
            gap={4}
            alignItems={'center'}
            style={{
              padding: '10px 20px',
                marginRight: 6 + 8,
                background: surfacePrimary
            }}
          >
            <Check />
            <Flex flex={1} />
            <Flex flex={1}>
              <HeaderButton>Name</HeaderButton>
            </Flex>
            <Flex flex={2}>
              <HeaderButton>Id</HeaderButton>
            </Flex>
            <Flex flex={1}>
              <HeaderButton>Type</HeaderButton>
            </Flex>
            <Flex flex={1}>
              <HeaderButton>Language</HeaderButton>
            </Flex>
            <Flex flex={1}>
              <HeaderButton>State</HeaderButton>
            </Flex>
            <Flex flex={1}>
              <HeaderButton>Jobs</HeaderButton>
            </Flex>
            <Flex flex={1}>
              <HeaderButton>Requests</HeaderButton>
            </Flex>
            <Flex flex={1}>
              <HeaderButton>Created Time</HeaderButton>
            </Flex>
          </Flex>
          <div
            style={{
              borderTop: `1px solid ${surfaceSecondary}`,
              width: '100%',
            }}
          ></div>
            <div style={{flex: 1, overflowY: 'scroll', overflowX: 'hidden'}}>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
                <Row
                    activeNode={
                        <StateLabel color={def} background={def01}>
                            ARCHIVED
                        </StateLabel>
                    }
                />
                <div
                    style={{
                        borderTop: `1px solid ${surfaceSecondary}`,
                        width: '100%',
                    }}
                ></div>
            </div>
        </Flex>
      </Flex>
    </Flex>
  )

}

export default ThemeView
