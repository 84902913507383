import {
  AutoCompleteInput,
  Button,
  CheckBox,
  Input,
} from 'frontcore'
import StatusIndicator from '../../components/StatusIndicator'
import numberValidator from '../../validators/numberValidator'
import minimumValidator from '../../validators/minimumValidator'
import maximumValidator from '../../validators/maximumValidator'
import {formatISODuration} from '../../helpers'
import {LEFT, RIGHT, TOP} from '../../constants'
import RadioButtons from '../../components/RadioButtons'
import SparkVersionAutoCompleteItem from '../../components/SparkVersionAutoCompleteItem'
import React from 'react'

const schema = (data, options) => {
  const {
    darkMode,
    fileExplorerTooltip = true,
    globalTooltip = true,
    workloadsTooltip = true,
    criticalThreshold = 90,
    warningThreshold = 70,
    navBarOrientation = LEFT,
    metricsRefreshRate = 600000,
    tabsRefreshRate = 600000,
    filtersRefreshRate = 600000,
    model = 'dupa',
  } = options?.settings || {}

  const {
    expire = 'N/A',
    status = 'Active',
    type = '7 Day Trial',
  } = options?.license || {}

  return {
    fields: [
      {
        id: 'navBarOrientation',
        section: 'Theme',
        name: 'Navigation Bar Orientation',
        defaultValue: navBarOrientation,
        description: 'Change position of navigation bar',
        Component: RadioButtons,
        componentProps: {
          fullWidth: true,
          options: [
            {id: LEFT, label: 'Left'},
            {id: TOP, label: 'Top'},
          ],
        },
      },

      {
        id: 'globalTooltip',
        section: 'Tooltip',
        name: 'Global',
        defaultValue: globalTooltip,
        description:
          'Enable tooltips for whole application',
        Component: CheckBox,
        componentProps: {
          skeleton: false,
          fullWidth: true,
          autoFocus: true,
        },
      },

      {
        id: 'workloadsTooltip',
        section: 'Tooltip',
        name: 'Workloads',
        defaultValue: workloadsTooltip,
        description:
          'Enable tooltips for WorkloadsView page',
        Component: CheckBox,
        componentProps: {
          skeleton: false,
          fullWidth: true,
          autoFocus: true,
        },
      },
      {
        id: 'fileExplorerTooltip',
        section: 'Tooltip',
        name: 'File Explorer',
        defaultValue: fileExplorerTooltip,
        description:
          'Enable tooltips for file explorer page',
        Component: CheckBox,
        componentProps: {
          skeleton: false,
          fullWidth: true,
          autoFocus: true,
        },
      },
      {
        id: 'darkMode',
        section: 'Theme',
        name: 'Dark Mode',
        defaultValue: darkMode,
        description: 'Enable dark mode',
        Component: CheckBox,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [],
      },
      /*      {
                    id: 'type',
                    section: 'License',
                    name: 'Type',
                    defaultValue: type,
                    description: '',
                    Component: Input,
                    componentProps: {
                      readOnly: true,
                      skeleton: false,
                      fullWidth: true,
                    },
                    validators: [],
                  },*/
      {
        id: 'status',
        section: 'License',
        name: 'Status',
        defaultValue: status,
        description: '',
        Component: ({value}) => (
          <div style={{display: 'flex'}}>
            <StatusIndicator
              value={value}
              variant={value}
            />
          </div>
        ),
        componentProps: {
          readOnly: true,
          skeleton: false,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'expirationDate',
        section: 'License',
        name: 'Expiration Date',
        defaultValue: formatISODuration(expire),
        description: '',
        Component: Input,
        componentProps: {
          readOnly: true,
          skeleton: false,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'key',
        section: 'License',
        name: 'Key',
        description: '',
        defaultValue: '',
        Component: Input,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'warningThreshold',
        section: 'Metrics',
        name: 'Warning Threshold',
        description: '',
        defaultValue: warningThreshold,
        Component: Input,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [
          numberValidator,
          minimumValidator(0),
          maximumValidator(100),
        ],
      },
      {
        id: 'criticalThreshold',
        section: 'Metrics',
        name: 'Critical Threshold',
        description: '',
        defaultValue: criticalThreshold,
        Component: Input,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [
          numberValidator,
          minimumValidator(0),
          maximumValidator(100),
        ],
      },
      {
        id: 'tabsRefreshRate',
        section: 'Metrics',
        name: 'Tabs Refresh Rate [ms]',
        description:
          'Assigning a value of 0 will result in a single call and completely disable any subsequent refreshing.',
        defaultValue: tabsRefreshRate,
        Component: Input,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [numberValidator, minimumValidator(0)],
      },
      {
        id: 'filtersRefreshRate',
        section: 'Metrics',
        name: 'Filters Refresh Rate [ms]',
        description:
          'Assigning a value of 0 will result in a single call and completely disable any subsequent refreshing.',
        defaultValue: filtersRefreshRate,
        Component: Input,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [numberValidator, minimumValidator(0)],
      },
      {
        id: 'metricsRefreshRate',
        section: 'Metrics',
        name: 'Metrics Refresh Rate [ms]',
        description:
          'Assigning a value of 0 will result in a single call and completely disable any subsequent refreshing.',
        defaultValue: metricsRefreshRate,
        Component: Input,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [numberValidator, minimumValidator(0)],
      },
      {
        id: 'llmModel',
        section: 'AI',
        name: 'Model',
        defaultValue: 'mistral',
        description: 'Choose LLM Model',
        Component: AutoCompleteInput,
        componentProps: {
          options: [
            {value: 'deepseek-r1:7b'},
            {value: 'mistral'},
          ],
          skeleton: false,
          fullWidth: true,
          ItemComponent: ({value, onClick}) => (
            <Button
              tabIndex={-1}
              rounding={false}
              variant={'text'}
              onClick={() => onClick(value)}
              Component={() => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div>{value}</div>
                </div>
              )}
            />
          ),
        },
        validators: [],
      },
      {
        id: 'llmBaseUrl',
        section: 'AI',
        name: 'Base URL',
        description: "Default URL api point to default Ollama setup",
        defaultValue: 'http://localhost:11434/api',
        Component: Input,
        componentProps: {
          skeleton: false,
          fullWidth: true,
        },
        validators: [


        ],
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: false,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: false,
          children: 'Cancel',
          variant: 'outlined',
        },
      },
    ],
  }
}

export default schema
