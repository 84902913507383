import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect, useMemo, useState} from 'react'
import useFuture from '../../../../hooks/useFuture'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {find, join, map, pathOr} from 'ramda'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from '../../../../constants'
import getGroup from '../../../../api/security/getGroup'
import {fork} from 'fluture'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import DetailsHeader from '../../../../components/DetailsHeader'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import ReactJson from 'react-json-view'
import LabelIcon from '../../../../components/Icons/LabelIcon'
import {Tabs} from 'frontcore'
import {CountMiniature} from '../../../../components/miniatures'
import GroupSingleButtons from '../../Components/GroupButtons/GroupSingleButtons'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFetch from '../../../../hooks/useFetch'
import getPermissionsFetch from '../../../../api/security/getPermissionsFetch'
import {singleCallFetchOptions} from '../../../../api/helpers'
import PermissionsSelect from '../../../../components/PermissionsSelect'
import StatusIndicator from "../../../../components/StatusIndicator";
import Chip from "../../../../components/Chip";
import Json from "../../../../components/Json";

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

const SecurityGroupDetailsView = () => {
  const getGroupFuture = useFuture(getGroup)

  const {groupId, clusterName} = useParams()

  const dispatch = useDispatch()

  const group = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'group',
      'response',
    ])
  )

  const {
    name,
    users,
    operations,
    enabled,
    roles,
    lastActivity,
    permissions = EMPTY_ARRAY,
    creationTime,
      description,
      state,
  } = group

  const {createNotification} = useNotificationContext()

  const [tab, setTab] = useState('permissions')

  const {data: permissionsOptions = EMPTY_ARRAY} = useFetch(
    getPermissionsFetch,
    singleCallFetchOptions
  )

  useEffect(() => {
    if (groupId) {
      fork(({message}) => {
        createNotification({
          title: 'Failed to fetch Group',
          message,
          variant: 'error',
          autoHide: true,
        })
      })(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getGroupFuture({
          params: [{key: 'id', value: groupId}],
        })
      )
    }
  }, [groupId])

  const topContent = (
    <DetailsHeader
      pending={false}
      icon={
        <Chip size={'large'} type={"GROUP"}>
          {name}
        </Chip>
      }
      data={[
        {
          key: 'description',
          value: description,
        },
        {
          key: 'state',
          value: state,
          Component: ({value}) => <StatusIndicator
              value={value}
              variant={value === 'ENABLED' ? 'success' : 'default'}
          />
        },
        {
          key: 'creation Time',
          value: creationTime,
          Component: DateCellRow,
        },
        {
          key: 'last Activity',
          value: lastActivity,
          Component: DateCellRow,
        },
        {
          key: 'users',
          value: users,
        },
        {
          key: 'activities',
          value: operations
        },
      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={'permissions'}
        onChange={setTab}
        options={[
          {
            id: 'permissions',
            Component: TabComponent,
            componentProps: {
              label: 'Permissions',
            },
          },
          {
            id: 'raw',
            Component: TabComponent,
            componentProps: {
              label: 'Raw',
            },
          },
        ]}
      />
      <GroupSingleButtons
        texts={true}
        getFuture={getGroupFuture({
          params: [{key: 'id', value: groupId}],
        })}
        pending={false}
        data={group}
        redirect={false}
      />
    </div>
  )

  const bottomContent = (
    <>
      {tab === 'raw' && <Json src={group} />}
      {tab === 'permissions' && (
        <PermissionsSelect
          value={permissions}
          options={permissionsOptions}
        />
      )}
    </>
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityGroupDetailsView
