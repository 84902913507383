import {useListRefreshFuture} from '../hooks'
import ListLayout from '../../../../components/layouts/listLayout'
import Filters from '../../../WorkloadsView/filters'
import React from 'react'
import ActivitiesList from './ActivitiesList'
import getActivities from '../../../../api/security/getActivities'
import usePending from '../../../../hooks/usePending'
import {isNotEmpty} from '../../../../helpers'
import GroupBatchButtons from '../../Components/GroupButtons/GroupBatchButtons'
import ActivityBatchButtons from '../../Components/ActivityButtons/ActivityBatchButtons'
import {Icon, Input, Select} from "frontcore";
import {EMPTY_ARRAY} from "../../../../constants";

const schema = [
    {
        id: 'search',
        Component: Input,
        defaultValue: '',
        props: {
            StartComponent: Icon,
            startComponentProps: {
                size: 16,
                icon: 'search',
            },
            placeHolder: 'Search',
            topLabelProps: {
                children: 'Users Search',
            },
            label: 'Users Search',
        },
    },
    {
        id: 'method',
        Component: Select,
        defaultValue: EMPTY_ARRAY,
        props: {
            label: 'State',
            value: [],
            options: ['POST', 'PUT', 'DELETE'],
            multiSelect: true,
            buttonProps: {
                width: '200px',
            },
        },
    },
]

const SecurityActivitiesListView = () => {
  const listHandler = useListRefreshFuture(
    getActivities,
    'security',
    'activities'
  )

  const {selected} = listHandler

  const pending = usePending(
    ['security', 'data'],
    ['activities']
  )

  return (
    <ListLayout
      batchButtonsNode={
        isNotEmpty(selected) && (
          <ActivityBatchButtons {...listHandler} />
        )
      }
      filtersNode={
        <Filters
          name={'activities'}
          schema={schema}
          pending={pending}
        />
      }
      contentNode={
        <ActivitiesList
          pending={pending}
          listHandler={listHandler}
        />
      }
    />
  )
}

export default SecurityActivitiesListView
