import ReactJson from 'react-json-view'
import React from 'react'
import {useSelector} from 'react-redux'
import {path} from 'ramda'

const Json = ({src}) => {
  const darkMode = useSelector(path(['settings', 'settings', 'darkMode']))

  return <ReactJson theme={darkMode ? 'chalk' : 'rjv-default'} src={src} />
}

export default Json
