import React, {useCallback} from 'react'
import {fork} from 'fluture'
import {pipe} from 'ramda'
import clusterFormSubmitFn from '../../helpers/clusterFormSubmitFn'
import {useNavigate} from 'react-router-dom'
import schema from './schema'
import FormLayout2 from '../../../../components/tabsFormLayout'

import useFuture from '../../../../hooks/useFuture'
import createCluster from '../../../../api/workloads/clusters/createCluster'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import usePending from '../../../../hooks/usePending'
import Form from '../../../../components/form'

const WorkloadsCreateClusterView = () => {
  const createClusterFuture = useFuture(createCluster)
  const {createNotification} = useNotificationContext()

  const navigate = useNavigate()

  const pending = usePending(
    ['workloads', 'data'],
    ['createCluster']
  )

  const handleOnSubmit = useCallback((fields) => {
    const params = {...fields}
    const result = pipe(clusterFormSubmitFn)(params)

    pipe(
      fork(({message}) => {
        createNotification({
          title: 'Failed to create Cluster',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(() => {
        navigate(-1)
        createNotification({
          message: 'Cluster created successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(createClusterFuture(result))
  }, [])

  return (
    <Form
      autocomplete={'off'}
      options={{
        pending,
        navigate,
      }}
      initialData={{type: 'local'}}
      LayoutComponent={FormLayout2}
      layoutComponentProps={{
        title: 'NEW CLUSTER',
      }}
      schema={schema}
      onSubmit={handleOnSubmit}
    />
  )
}

export default WorkloadsCreateClusterView
