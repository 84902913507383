import DetailsInfo from '../../views/WorkloadsView/detailsInfo'
import React from 'react'
import styled from 'styled-components'

const typeMap = {
  cluster: 'cluster',
  service: 'service',
  schedule: 'schedule',
  job: 'job',
  request: 'request',
  timelineJob: 'job',
}

const iconMap = {
  cluster: 'cluster',
  service: 'box',
  schedule: 'clock',
  job: 'database',
  request: 'table',
  metastore: 'server',
  database: 'database',
  table: 'table',
  catalog: 'directory',
  storage: 'server',
  bucket: 'trash',
  folder: 'directory',
  root: 'home',
  timelineJob: 'table',
  timelineStage: 'table',
  executor: 'gear',
  'kubernetes-pod': 'kubernetes',
  'spark-submit-process': 'spark',
}

const NameContainer = styled.div`
  font-size: 16px;
  font-family: Inter, serif;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const IdContainer = styled.div`
  font-size: 12px;
  font-family: Inter, serif;
  color: ${({theme}) => theme.palette.text.tertiary};
`

const DetailsHeader = ({type, name, id, data, pending, icon}) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: 64,
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
          maxWidth: 200,
        }}
      >
        {icon}
        {/*<div*/}
        {/*  style={{*/}
        {/*    background: colorMap[type] || 'black',*/}
        {/*    width: 64,*/}
        {/*    height: 64,*/}
        {/*    display: 'flex',*/}
        {/*    alignItems: 'center',*/}
        {/*    justifyContent: 'center',*/}
        {/*    borderRadius: 2,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Icon*/}
        {/*    icon={iconMap[type]}*/}
        {/*    size={40}*/}
        {/*    color={ 'white'}*/}
        {/*  />*/}
        {/*</div>*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >

          <NameContainer>{name || id}</NameContainer>
          <IdContainer>{id}</IdContainer>
          <IdContainer>{typeMap[type] || type}</IdContainer>
        </div>
      </div>
      <div style={{flex: 1, overflow: 'hidden'}}>
      <DetailsInfo
        skeleton={pending}
        data={data}
      />
      </div>
    </div>
  )
}

export default DetailsHeader
