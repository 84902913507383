import {always, equals, identity, ifElse, map,} from 'ramda';

// The runtime-env-cra library doesn't support empty environment variable values.
// As a workaround, default string values of 'undefined' were introduced.
// Utilize this helper function to safely access environment variables.

const mapUndefinedDefaultValues = envs => map(
    ifElse(
        equals('undefined'),
        always(''),
        identity
    ),
    envs
);

export const {
    ILUM_ENVIRONMENT,
    ILUM_DOC_ENABLED,
    ILUM_SQL_ENABLED,
    ILUM_JUPYTER_ENABLED,
    ILUM_AIRFLOW_ENABLED,
    ILUM_DATA_ENABLED,
    ILUM_MLFLOW_ENABLED,
    ILUM_HISTORY_SERVER_ENABLED,
    ILUM_LINEAGE_ENABLED,
    ILUM_SUPERSET_ENABLED,
    ILUM_GITEA_ENABLED,
    ILUM_BACKEND_URL,
    ILUM_JUPYTER_PATH,
    ILUM_AIRFLOW_PATH,
    ILUM_DATA_PATH,
    ILUM_MLFLOW_PATH,
    ILUM_HISTORY_SERVER_PATH,
    ILUM_LINEAGE_PATH,
    ILUM_SUPERSET_PATH,
    ILUM_GITEA_PATH,
    ILUM_OAUTH2_CLIENT_ID,
    ILUM_OAUTH2_CLIENT_SECRET,
    ILUM_OAUTH2_ISSUER_URI
} = mapUndefinedDefaultValues(window.__RUNTIME_CONFIG__);
