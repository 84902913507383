import {useCallback} from 'react'
import {always, join, map, pipe, when} from 'ramda'
import {and, fork, parallel} from 'fluture'
import {useNavigate} from 'react-router-dom'
import UserButtons from '../index'
import {useNotificationContext} from '../../../../../hooks/useNotificationsContext'
import useFuture from '../../../../../hooks/useFuture'
import {deleteUser} from '../../../../../api/security/deleteUser'
import {enableUser} from '../../../../../api/security/enableUser'
import {disableUser} from '../../../../../api/security/disableUser'
import {EMPTY_ARRAY} from '../../../../../constants'

const UserBatchButtons = ({
  pending,
  data,
  texts,
  getFuture,
  selected,
  setSelected,
}) => {
  const {id} = data

  const {createNotification} = useNotificationContext()

  const deleteUserFuture = useFuture(deleteUser)
  const enableUserFuture = useFuture(enableUser)
  const disableUserFuture = useFuture(disableUser)

  const handleOnDelete = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to delete Users',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        setSelected(EMPTY_ARRAY)
        createNotification({
          message,
          title: 'Users deleted successfully',
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      deleteUserFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  const handleOnEnable = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to enable Users',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Users enabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      enableUserFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  const handleOnDisable = useCallback(() => {
    pipe(
      and(getFuture),
      fork(({message}) => {
        createNotification({
          title: 'Failed to disable Users',
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          title: 'Users disabled successfully',
          message,
          autoHide: true,
          variant: 'success',
        })
      })
    )(
      disableUserFuture({
        params: [
          {key: 'id', value: join(',', selected || [])},
        ],
      })
    )
  }, [selected, getFuture])

  return (
    <UserButtons
      texts={false}
      id={id}
      data={data}
      pending={pending}
      onEnable={handleOnEnable}
      onDisable={handleOnDisable}
      onDelete={handleOnDelete}
    />
  )
}

export default UserBatchButtons
