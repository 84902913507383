import DetailsLayout from '../../../../components/layouts/detailsLayout'
import React, {useEffect, useMemo, useState} from 'react'
import DetailsHeader from '../../../../components/DetailsHeader'
import useFuture from '../../../../hooks/useFuture'
import {useDispatch, useSelector} from 'react-redux'
import {setBreadCrumbs} from '../../../../reducers/workloads'
import {fork} from 'fluture'
import getUser from '../../../../api/security/getUser'
import {useParams} from 'react-router-dom'
import {find, join, map, pathOr, toUpper} from 'ramda'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from '../../../../constants'
import ReactJson from 'react-json-view'
import DateCellRow from '../../../WorkloadsView/dateCellRow'
import UserIcon from '../../../../components/Icons/UserIcon'
import {Tabs} from 'frontcore'
import UserSingleButtons from '../../Components/UserButtons/UserSingleButtons'
import {CountMiniature} from '../../../../components/miniatures'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import PermissionsSelect from '../../../../components/PermissionsSelect'
import useFetch from '../../../../hooks/useFetch'
import getPermissionsFetch from '../../../../api/security/getPermissionsFetch'
import {singleCallFetchOptions} from '../../../../api/helpers'
import StatusIndicator from "../../../../components/StatusIndicator";
import Json from "../../../../components/Json";

const TabComponent = ({active, type, value = 0, label}) => (
  <div
    style={{display: 'flex', gap: 8, alignItems: 'center'}}
  >
    {label}
    <CountMiniature
      value={value}
      active={active}
      type={type}
    />
  </div>
)

const SecurityUserDetailsView = () => {
  const getUserFuture = useFuture(getUser)

  const {userId, clusterName} = useParams()

  const dispatch = useDispatch()

  const [tab, setTab] = useState('permissions')

  const {data: permissionsOptions} = useFetch(
    getPermissionsFetch,
    singleCallFetchOptions
  )

  const user = useSelector(
    pathOr(EMPTY_OBJECT, [
      'security',
      'data',
      'user',
      'response',
    ])
  )

  const {
    id,
    username,
    email,
    fullname,
    description,
      name,
    department,
    creationTime,
    enabled,
    roles,
    groups,
      state,
    permissions = EMPTY_ARRAY,
  } = user

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    if (userId) {
      fork(({message}) => {
        createNotification({
          title: 'Failed to fetch User',
          message,
          variant: 'error',
          autoHide: true,
        })
      })(({id, name}) => {
        dispatch(
          setBreadCrumbs({
            cluster: {
              id,
              name,
              active: true,
            },
          })
        )
      })(
        getUserFuture({
          params: [{key: 'id', value: userId}],
        })
      )
    }
  }, [userId])

  const topContent = (
    <DetailsHeader
      icon={
        <UserIcon color={'#FF8B96'} size={'large'}>
          {toUpper(name?.substring(0, 1) || '')}
        </UserIcon>
      }
      type={email}
      name={name}
      pending={false}
      data={[
        {
          key: 'fullname',
          value: fullname,
        },
        {
          key: 'department',
          value: department,
        },
        {
          key: 'Creation Time',
          value: creationTime,
          Component: DateCellRow,
        },
        {
          key: 'state',
          value: state,
          Component: ({value}) => <StatusIndicator
              value={value}
              variant={value === 'ENABLED' ? 'success' : 'default'}
          />
        },
      ]}
    />
  )

  const middleContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={tab}
        onChange={setTab}
        options={[
          {
            id: 'permissions',
            Component: TabComponent,
            componentProps: {
              label: 'Permissions',
            },
          },
          {
            id: 'raw',
            Component: TabComponent,
            componentProps: {
              label: 'Raw',
            },
          },
        ]}
      />
      <UserSingleButtons
        texts={true}
        getFuture={getUserFuture({
          params: [{key: 'id', value: userId}],
        })}
        pending={false}
        data={user}
        redirect={false}
      />
    </div>
  )



  const bottomContent = (
    <>
      {tab === 'raw' && <Json src={user} />}
      {tab === 'permissions' && (
        <PermissionsSelect
          value={permissions}
          options={permissionsOptions}
        />
      )}
    </>
  )

  return (
    <DetailsLayout
      topContent={topContent}
      middleContent={middleContent}
      bottomContent={bottomContent}
    />
  )
}

export default SecurityUserDetailsView
