import {
  Button,
  CheckBox,
  Input,
  MultiSelectTemplate,
  Select,
} from 'frontcore'
import PermissionsSelect from '../../../../components/PermissionsSelect'
import {EMPTY_ARRAY} from '../../../../constants'
import {filter, includes, map, pipe} from 'ramda'
import ToolTip from '../../../../components/toolTip'
import requiredValidator from "../../../../validators/requiredValidator";

const ButtonComponentMultiSelect = ({value, disabled}) => {
  const extendedValue = pipe(map(({name}) => name))(value)
  return (
    <MultiSelectTemplate
      value={extendedValue}
      disabled={disabled}
    />
  )
}

const GroupsButtonComponentMultiSelect = ({value, disabled}) => {
  const extendedValue = pipe(map(({name}) => name))(value)
  return (
    <MultiSelectTemplate
      value={extendedValue}
      disabled={disabled}
    />
  )
}

const ItemComponent = ({
  value,
  onClick,
  active,
  permissions,
}) => {


  const aaa = filter(({name}) => includes(name, value?.permissions), permissions)

  return (

    <ToolTip
      lockable={true}
      content={
        <div style={{padding: 8}}>
          <PermissionsSelect
            options={permissions}
            value={aaa}
          />
        </div>
      }
    >
      <Button
        onClick={onClick}
        variant={'text'}
        rounding={false}
        fullWidth
        tabIndex={-1}
        active={active}
        Component={() => (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>{value?.name}</div>
          </div>
        )}
      />
    </ToolTip>
  )
}

const schema = (data, options) => {
  const {
    pending,
    navigate,
    permissions = EMPTY_ARRAY,
    roles = EMPTY_ARRAY,
    groups = EMPTY_ARRAY
  } = options

  return {
    fields: [
      {
        id: 'name',
        section: 'General',
        name: 'Username',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        id: 'password',
        section: 'General',
        name: 'Password',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [requiredValidator],
      },
      {
        id: 'fullname',
        section: 'General',
        name: 'Fullname',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'email',
        section: 'General',
        name: 'Email',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'description',
        section: 'General',
        name: 'Description',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'department',
        section: 'General',
        name: 'Department',
        description:
          '',
        Component: Input,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
        },
        validators: [],
      },
      {
        id: 'roles',
        section: 'Access',
        name: 'Roles',
        description:
          '',
        Component: Select,
        componentProps: {
          buttonProps: {
            fullWidth: true,
            Component: ButtonComponentMultiSelect,
          },
          multiSelect: true,
          options: roles,
          skeleton: pending,
          ItemComponent,
          itemComponentProps: {
            permissions,
          },
          autoFocus: true,
        },
        validators: [],
      },
      {
        id: 'groups',
        section: 'Access',
        name: 'Groups',
        description:
          '',
        Component: Select,
        componentProps: {
          buttonProps: {
            fullWidth: true,
            Component: GroupsButtonComponentMultiSelect,
          },
          ItemComponent,
          itemComponentProps: {
            permissions,
          },
          multiSelect: true,
          options: groups,
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
        },
        validators: [],
      },
      {
        id: 'permissions',
        section: 'Access',
        name: 'Permissions',
        description:
          '',
        Component: PermissionsSelect,
        componentProps: {
          options: permissions,
          skeleton: pending,
        },
        validators: [],
      },
      {
        id: 'state',
        section: 'General',
        name: 'Enabled',
        description:
          '',
        Component: CheckBox,
        componentProps: {
          skeleton: pending,
          fullWidth: true,
          autoFocus: true,
          validators: [],
        },
      },
    ],
    buttons: [
      {
        id: 'submit',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Submit',
          type: 'submit',
          color: 'success',
        },
      },
      {
        id: 'cancel',
        Component: Button,
        componentProps: {
          tabIndex: -1,
          skeleton: pending,
          children: 'Cancel',
          variant: 'outlined',
          onClick: () => navigate(-1),
        },
      },
    ],
  }
}

export default schema
