import styled, { css } from 'styled-components';

// Define the types
type JustifyContent =
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'safe center'
    | 'unsafe center'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';

type FlexDirection =
    | 'row'
    | 'row-reverse'
    | 'column'
    | 'column-reverse'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';

type AlignItems =
    | 'normal'
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
    | 'anchor-center'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'safe center'
    | 'unsafe center'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';

interface FlexProps {
    flexDirection?: FlexDirection;
    justifyContent?: JustifyContent;
    alignItems?: AlignItems;
    gap?: number;
    flex?: number;
}


const gapCss = css<FlexProps>`
    gap: ${({ gap = 0 }) => gap * 6 + 'px'};
`;

const flexCss = css<FlexProps>`
    flex: ${({ flex = 0 }) => flex};
`;

const justifyContentCss = css<FlexProps>`
    justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
`;

const alignItemsCss = css<FlexProps>`
    align-items: ${({ alignItems = 'normal' }) => alignItems};
`;

const Flex = styled.div<FlexProps>`
    display: flex;
    flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
    ${({justifyContent}) => justifyContent && justifyContentCss};
    ${({alignItems}) => alignItems && alignItemsCss};
    ${({gap}) => gap && gapCss};
    ${({flex}) => flex && flexCss};
    overflow: hidden;
`;

export default Flex;
