import {useNavigate} from 'react-router-dom'
import FormLayout2 from '../../../../components/tabsFormLayout'
import React, {useCallback} from 'react'
import Form from '../../../../components/form'
import schema from './schema'
import useFuture from '../../../../hooks/useFuture'
import {createUser} from '../../../../api/security/createUser'
import {fork} from 'fluture'
import {useNotificationContext} from '../../../../hooks/useNotificationsContext'
import useFetch from '../../../../hooks/useFetch'
import getPermissionsFetch from '../../../../api/security/getPermissionsFetch'
import {singleCallFetchOptions} from '../../../../api/helpers'
import getRolesFetch from '../../../../api/security/getRolesFetch'
import {EMPTY_ARRAY} from '../../../../constants'
import {getGroupsFetch} from '../../../../api/security/getGroupsFetch'
import {__, evolve, map, or, pipe, prop} from 'ramda'

const SecurityCreateUserView = () => {
  const navigate = useNavigate()

  const createUserFuture = useFuture(createUser)

  const {createNotification} = useNotificationContext()

  const {data: permissions} = useFetch(
    getPermissionsFetch,
    singleCallFetchOptions
  )

  const {data: roles} = useFetch(
    getRolesFetch,
    singleCallFetchOptions
  )

  const {data: groups} = useFetch(
    getGroupsFetch,
    singleCallFetchOptions
  )

  const handleOnSubmit = useCallback((fields) => {

    const result = pipe(
      evolve({
        permissions: pipe(or(__, []), map(prop('name'))),
        roles: pipe(or(__, []), map(prop('id'))),
        groups: pipe(or(__, []), map(prop('id'))),
        state: (value) => (value ? 'ENABLED' : 'DISABLED'),
      })
    )(fields)

    fork(({message}) => {
      createNotification({
        title: 'Failed to create User',
        message,
        autoHide: false,
        variant: 'error',
      })
    })(() => {
      navigate(-1)
      createNotification({
        title: 'User created successfully',
        autoHide: true,
        variant: 'success',
      })
    })(createUserFuture(result))
  }, [])

  return (
    <Form
      autocomplete={'off'}
      options={{
        pending: false,
        navigate,
        permissions,
        roles: roles?.content || EMPTY_ARRAY,
        groups: groups?.content || EMPTY_ARRAY,
      }}
      LayoutComponent={FormLayout2}
      layoutComponentProps={{
        title: 'NEW USER',
      }}
      schema={schema}
      onSubmit={handleOnSubmit}
    />
  )
}

export default SecurityCreateUserView
