import styled from 'styled-components'
import {ViewTitle} from '../../components/typography'
import {Input} from 'frontcore'
import {useDispatch, useSelector} from 'react-redux'
import {
  path,
  pathOr,
  pipe,
  startsWith,
  toLower,
} from 'ramda'
import React, {useCallback, useEffect} from 'react'
import {
  setSearch,
  setSettings,
} from '../../reducers/settings'
import Form from '../../components/form'
import schema from './schema'
import SettingsFormLayout from '../../components/settingsFormLayout'
import Line from '../../components/line'
import {
  camelCaseToWhiteSpace,
  isNotEmpty,
  isNotNull,
} from '../../helpers'
import useFuture from '../../hooks/useFuture'
import getLicense from '../../api/getLicense'
import {fork} from 'fluture'
import {EMPTY_OBJECT} from '../../constants'
import createLicense from '../../api/createLicense'
import {useNotificationContext} from '../../hooks/useNotificationsContext'

const SettingsHeader = styled.div`
  padding: 0 16px;
  padding-bottom: 20px;
  margin-bottom: 20px;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const checkSearch = (value, search) =>
  pipe(
    camelCaseToWhiteSpace,
    toLower,
    startsWith(toLower(search))
  )(value)

const SettingsView = () => {
  const dispatch = useDispatch()

  const search = useSelector(path(['settings', 'search']))
  const handleSearchChange = useCallback(
    pipe(setSearch, dispatch),
    []
  )

  const settings = useSelector(
    path(['settings', 'settings'])
  )

  const getLicenseFuture = useFuture(getLicense)
  const createLicenseFuture = useFuture(createLicense)

  const license = useSelector(
    pathOr(EMPTY_OBJECT, [
      'workloads',
      'data',
      'getLicense',
      'response',
    ])
  )

  const {createNotification} = useNotificationContext()

  useEffect(() => {
    fork(() => {})(() => {})(getLicenseFuture({}))
  }, [])

  const handleOnSubmit = useCallback((fields) => {
    dispatch(setSettings(fields))
    createNotification({
      message: 'Settings have been successfully saved',
      autoHide: true,
      variant: 'success',
    })
    if (isNotNull(fields.key) && isNotEmpty(fields.key)) {
      fork(({message}) => {
        createNotification({
          message,
          autoHide: false,
          variant: 'error',
        })
      })(({message}) => {
        createNotification({
          message,
          autoHide: true,
          variant: 'success',
        })
      })(createLicenseFuture({key: fields.key}))
    }
  }, [])

  return (
    <Root>
      <SettingsHeader>
        <ViewTitle>Settings</ViewTitle>
        <div style={{width: 360}}>
          <Input
            placeHolder="Search in settings..."
            value={search}
            onChange={handleSearchChange}
          />
        </div>
      </SettingsHeader>
      <Line />
      <Form
        autocomplete={'off'}
        options={{settings, license}}
        LayoutComponent={SettingsFormLayout}
        layoutComponentProps={{
          title: 'Settings',
        }}
        schema={schema}
        onSubmit={handleOnSubmit}
      />
    </Root>
  )
}

export default SettingsView
