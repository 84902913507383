import useSWR from 'swr'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  calculateHeaders,
  calculateUrl,
} from '../api/helpers'
import {EMPTY_OBJECT} from '../constants'
import {useAuth} from 'react-oidc-context'
import {startsWith} from 'ramda'

const useFetch = (
  {path, method, api = true},
  options = {
    refreshInterval: 10000,
  },
  args = EMPTY_OBJECT
) => {
  const navigate = useNavigate()
  const {pathname} = useLocation()

  const headers = calculateHeaders({})
  const url =
    !!path && calculateUrl(args, path, method, api)

  const {removeUser} = useAuth()

  const fetcher = (url) => {
    const controller = new AbortController()

    const promise = fetch(url, {
      method,
      headers,
    })
      .then((r) => {
        const status = r.status

        if (status === 409) {
          return r.json().then((a) => ({...a, status}))
        }
        if (status === 403) {
          return r.json().then((a) => ({...a, status}))
        }
        if (status === 404) {
          throw r
        }
        if (status === 401) {
          if (pathname !== '/login') {
            navigate('/login')
            removeUser()
            localStorage.removeItem('token')
            localStorage.setItem('cachedUrl', pathname)
          }
          return '{}'
        } else {
          return r.json()
        }
      })
      .then((a) => {
        if (a.status === 409) {
          throw new Error(a.status + ': ' + a.message)
        } else if (a.status === 403) {
          throw new Error(a.status + ': ' + a.message)
        } else {
          return a
        }
      })
    promise.abort = () => {
      controller.abort()
    }
    return promise
  }

  return useSWR(!!path && url, fetcher, options)
}

export default useFetch
