import {ResizeLayout, VirtualizedList} from 'frontcore'
import React, {useEffect, useMemo, useState} from 'react'
import {TabContainer} from '../JobDetailsTimelineView'
import {
  EMPTY_ARRAY,
  HORIZONTAL,
} from '../../../../../constants'
import useFetch from '../../../../../hooks/useFetch'
import getJobResourcesFetch from '../../../../../api/workloads/jobs/getJobResourcesFetch'
import {useParams} from 'react-router-dom'
import {
  ascend,
  assoc,
  equals,
  find,
  head,
  isEmpty,
  map,
  path,
  pipe,
  sortWith,
} from 'ramda'
import ResourceLogs from '../../../resourceLogs'
import {RightContainer, Root} from '../styles'
import StatusIndicator from '../../../../../components/StatusIndicator'
import NoItemsFound from '../../../../../components/NoResultFound'
import RButton from '../../../../../components/RButton'

const iconMap = {
  'kubernetes-pod': 'kubernetes',
  'spark-submit-process': 'spark',
}

const ListButton = (props) => {
  const {
    type,
    onClick,
    name,
    role,
    state,
    active = false,
  } = props

  return (
    <TabContainer leftShift={8}>
      <RButton
        fullWidth={true}
        icon={iconMap[type] || 'gear'}
        name={name}
        type={type + '   ' + role}
        active={active}
        onClick={() => onClick({name, role, state, type})}
      >
        <StatusIndicator
          variant={
            state === 'running' ? 'success' : 'default'
          }
          value={state}
        />
      </RButton>
    </TabContainer>
  )
}

const JobDetailsLogs = () => {
  const {jobId} = useParams()

  const {data = EMPTY_ARRAY} = useFetch(
    getJobResourcesFetch,
    {
      refreshInterval: 3000,
    },
    {params: [{key: 'id', value: jobId}]}
  )

  const [selected, setSelected] = useState()

  useEffect(() => {
    if (!selected && data?.[0]) {
      const result = pipe(
        sortWith([ascend(path(['role']))]),
        head
      )(data)
      setSelected(result)
    }
  }, [selected, data])

  const extendedData = useMemo(
    () =>
      pipe(
        map((item) => {
          const active = equals(item.name, selected?.name)
          return assoc('active', active, item)
        }),
        sortWith([ascend(path(['role']))])
      )(data),
    [selected, data]
  )

  const extendedSelected = useMemo(() => {
   const result =  find(({name}) => name === selected, extendedData)


      return ({...selected,... result})
  }, [selected, extendedData])

  return (
    <Root>
      <ResizeLayout
        configuration={{
          orientation: HORIZONTAL,
          firstNode: {
            measurement: 'px',
            value: 260,
          },
          secondNode: {
            measurement: 'flex',
            value: 1,
          },
        }}
        firstNode={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
            }}
          >
            {isEmpty(extendedData) ? (
              <NoItemsFound />
            ) : (
              <VirtualizedList
                items={extendedData}
                itemHeight={32}
                gap={4}
                ItemComponent={ListButton}
                itemComponentProps={{
                  onClick: ({name}) => {
                    setSelected(name)
                  },
                }}
              />
            )}
          </div>
        }
        secondNode={
          <RightContainer>
            <ResourceLogs
              data={extendedSelected}
              jobId={jobId}
            />
          </RightContainer>
        }
      />
    </Root>
  )
}

export default JobDetailsLogs
